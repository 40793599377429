import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import WellnessBenefitsStyle from "ps-assets/jss/WellnessBenefitsStyle.jsx";
import {  browserWindow } from "util/localStorageHelper";
import { Helmet } from "react-helmet";
import Button from 'components/CustomButtons/Button.jsx';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Accordion from "components/Accordion/Accordion.jsx";
// const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

const table1 = [
    ['Product type', 'Individual / Floater','Individual / Floater','Individual / Floater','Individual','Floater'],
    ['Policy period', '1 /2 /3 - Years','1 /2 /3 - Years','1 /2 /3 - Years','1 / 2  - Years','1 / 2  - Years'],
]

const faq = [
    {
        title:'Can I get maternity insurance while I am pregnant?',
        description:<h5>Unfortunately No, You can buy a health insurance policy when you are pregnant. Maternity benefits cannot be availed as delivery coverage is subject to a minimum of 12 months waiting period (under the Super Surplus Gold Plan). Star Comprehensive Insurance Policy and Young Star Insurance Policy covers delivery expenses with a waiting period of 24 months and 36 months respectively.</h5>,
        content:false
    },
    {
        title:'Is there any maternity insurance without a waiting period?',
        description:<h5>Unfortunately, Maternity insurance is not under the retail health insurance policies. Delivery expenses have some waiting period ranging from 12 months – 36 months. However, you may be exempted from the waiting period for maternity coverage in a group tailor made policy.</h5>,
        content:false
    },
    {
        title:'Can my new born get coverage under the maternity policy?',
        description:<h5>Yes, your newborn can be added to the policy at the time of renewal or can be included mid-term, subject to a prior intimation from the date of birth.</h5>,
        content:false
    },
    {
        title:'When should I get a maternity health insurance plan?',
        description:<h5>At least 3 years to 1 year in advance is suggested, to ensure that the waiting periods in any policy do not affect its coverage when planning a baby.</h5>,
        content:false
    },
    {
        title:'How do I get medical insurance for my newborn baby?',
        description:<h5>The feature of adding the newborn in the middle of the Policy Period is termed as Mid-term Inclusion. You can cover your newborn under your existing medical insurance, provided the policy facilitates the coverage. Just intimate Star health insurance within 90 days from the date of birth of the newborn baby, the newborn baby will be covered from 91st day. Under Health insurance policies like Comprehensive Insurance Policy and Young Star Insurance Policy have the feature of mid-term inclusion. Mid-term inclusion is as per the terms and conditions of the Policy</h5>,
        content:false
    },
    {
        title:'Does the policy cover more than one delivery expense?',
        description:<h5>Yes, of course. The Comprehensive Insurance Policy and Young Star Health Insurance policies provide benefits to cover the second child’s birth. Maternity-related benefits are available for two deliveries during the lifetime under this policy.</h5>,
        content:false
    }
]

const ParentsPageSEO = ({ classes }) => {
    let AccordionArray = faq.map(category => {
        return {
          title: category.title,
          content: (
            <>
            {category.content ? category.description : <h5>{category.description}</h5>}
            </>
          )
        };
      });


      return (
        <GridContainer className={classes.containerDisplay}>
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                {/* <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>Health Insurance for Parents - Listing Page</h2>
                </GridItem> */}
                <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>Maternity Health Insurance in India</h2>
                </GridItem>
                <h5>Becoming a new parent and bringing a new life into the world ushers along with joy, happiness, and excitement. Just like the quote goes, “With great power comes, great responsibility”, becoming a parent brings the responsibility of caring for a new life. While this is an exciting phase of life, uncertainties may crop up, and it is always better to be prepared.</h5>
                <h5>We realise that growing medical inflation and escalating hospitalisation costs are putting a strain on the couple’s finances. As a result, Star Health Insurance provides Maternity health Insurance Policies, so you no longer have to worry about growing medical costs.</h5>
                <h5>As Per IRDAI Maternity expenses is termed as medical treatment expenses traceable to childbirth including normal or cesarean sections incurred during hospitalisation. It includes expenses towards lawful termination of pregnancy during the policy period.</h5>
                <h5>A medical policy with delivery benefits is essential to meet the growing expenses, particularly when it comes to prenatal care, doctor’s visits, delivery, and post-natal care. </h5>
                <h5>A medical policy with delivery benefits ensures the mother and baby are well protected while labour, particularly if any complications arise during the delivery and the early days of the child’s life.</h5>
                <h2 className={classes.h2hTitle}>Why do you need delivery expense coverage as a part of your regular health insurance? </h2>
                <h5>“Nearly 41% of all deaths in under-fives occur among new born, babies in their first 28 days of life, or babies in the neonatal period”, says WHO on its report on new born death and illness. </h5>
                <h5>The average cost of Normal or C-section deliveries are rising and can go up to ₹ 2 lakhs and above in most cities in India.</h5>
                <h5>Although there are limited maternity health insurance policies in India, one of the key benefits of Star Health Insurance’s individual and family floater policies is that they cover expenses incurred during delivery and for the newborn.</h5>
                <h5>This is a great feature if you are looking to buy a Mediclaim plan with delivery expense coverage for your spouse or your family. Maternity cover provided as a part of your health insurance can cover expenses that arise due to normal delivery or cesarean delivery and/or if the baby has been hospitalised due to any medical complications.</h5>
                <h5>Whether you plan to purchase a Mediclaim policy with delivery benefits or you are looking to switch to a family policy, Star Health Insurance provides soon-to-be parents with the right medical insurance and support they need to have healthy and happy pregnancies.</h5>
                <h5>As we are aware, having a child comes with its own joys and expenses. And these expenses could take a toll on the finances and well-being of new parents.</h5>
                <h5>Hence, it is important to purchase a medical insurance policy that covers maternity-related expenses well before pregnancy. </h5>
                <h2 className={classes.h2hTitle}>Star Health policies covering delivery expenses?</h2>
                {/* <h5>Health Insurance cover for Parents can be classified based on the age - Less than 60 years | More than 60 years. Some of the recommended health insurance policies for parents at Star Health are as follows:</h5> */}
                        <Paper className={classes.root}>
                            <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                                <TableHead>
                                        <TableRow>
                                            <TableCell
                                                rowSpan={1}
                                                colSpan={2}
                                                className={classes.tableCellwidth + ` ` + classes.headCell}
                                                // style={{minWidth:100, maxWidth:100}}
                                            >
                                               Policy Name
                                        </TableCell>
                                        <TableCell
                                            colSpan={1}
                                            align="center"
                                            className={classes.tableCellwidth + ` ` + classes.headCell}
                                        >
                                            Star Comprehensive Insurance Policy
                                    </TableCell>
                                    <TableCell
                                            colSpan={1}
                                            align="center"
                                            className={classes.tableCellwidth + ` ` + classes.headCell}
                                        >
                                            Young Star Insurance Policy (Gold Plan)
                                    </TableCell>
                                    <TableCell
                                            colSpan={1}
                                            align="center"
                                            className={classes.tableCellwidth + ` ` + classes.headCell}
                                        >
                                            Star Women Care Insurance Policy
                                    </TableCell>
                                    <TableCell
                                            colSpan={1}
                                            align="center"
                                            className={classes.tableCellwidth + ` ` + classes.headCell}
                                        >
                                            Super Surplus Insurance Policy (Gold Plan)
                                    </TableCell>
                                    <TableCell
                                            colSpan={1}
                                            align="center"
                                            className={classes.tableCellwidth + ` ` + classes.headCell}
                                        >
                                            Star Super Surplus(Floater) Insurance Policy (Gold Plan)
                                    </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                <TableRow>
                    <TableCell
                      rowSpan={3}
                      colSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Entry Age
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      colSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Adult
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                      18- 65 years
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        18- 40 years
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                       18 - 75 Years
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        18 - 65 years
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        18 - 65 years
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        Dependent Child
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        91 days to 25 Years
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        91 days to 25 Years
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        91 days to 25 Years
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        91 days to 25 Years
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        91 days to 25 Years
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        if Daughter unmarried / not earning - till 30 yrs.
                    </TableCell>
                  </TableRow>
                                {table1.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell colSpan={2} align="center" className={classes.tableCell}>
                                    {row[0]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={1}
                                className={classes.tableCell}>
                                    {row[1]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={1}
                                className={classes.tableCell}>
                                    {row[2]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={1}
                                className={classes.tableCell}>
                                    {row[3]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={1}
                                className={classes.tableCell}>
                                    {row[4]}
                                </TableCell>
                                <TableCell align="center" 
                                colSpan={1}
                                className={classes.tableCell}>
                                    {row[5]}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                        <TableCell
                      rowSpan={4}
                      colSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        Sum Insured (S.I.) Rs.  (Lakhs)
                    </TableCell>
                    <TableCell
                      rowSpan={4}
                      align="center"
                      className={classes.tableCell}
                    >
                        5 / 7.5/ 10/ 15 / 20/ 25 / 50 / 75 / 100 Lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        Individual - 3 lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={4}
                      align="center"
                      className={classes.tableCell}
                    >
                        5 / 10 / 15 /20 / 25 /50 / 100 Lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        SI: 5 / 7 / 10 / 15 / 20 / 25 / 50 / 75 / 100 Lakhs 
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        SI: 5 / 10 / 15 / 20 / 25 / 50 / 75 / 100 Lakhs 
                    </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Defined Limit: 3 Lakhs
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Defined Limit: 3 / 5 / 10 / 15 /  20 /  25 Lakhs 
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        Individual & Floater -  5 /10 / 15 /20 /25 / 50 / 75 / 100 Lakhs 
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        SI: 5 / 10 / 15 / 20 / 25 / 50 / 75 / 100 Lakhs 
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                         
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Defined Limit: 5 / 10 / 15 /  20 /  25 Lakhs 
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                         
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={1}
                      colSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        Cumulative Bonus for every claim-free year
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Up to 100%  S.I.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Up to 100%  S.I.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Up to 100%  S.I.
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Nil
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Nil
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={3}
                      colSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                       Maternity coverage & waiting Period
                    </TableCell>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                        Yes & 24 months
                    </TableCell>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                        Yes & 36 months
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Yes, 
                    </TableCell>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                        Yes & 12 months
                    </TableCell>
                    <TableCell
                      rowSpan={3}
                      align="center"
                      className={classes.tableCell}
                    >
                        Yes & 12 months
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        24 months for 5 /10 lakhs S.I.
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        12 months for 15 lakhs and above S.I.
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell
                      rowSpan={1}
                      colSpan={2}
                      align="center"
                      className={classes.tableCell}
                    >
                        Mid-term inclusion
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Available
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Available
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Available
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Not available
                    </TableCell>
                    <TableCell
                      rowSpan={1}
                      align="center"
                      className={classes.tableCell}
                    >
                        Not available
                    </TableCell>
                    </TableRow>
                            </TableBody>
                        </Table>
                        </Paper>
                        <h2 className={classes.h2hTitle}>Star Comprehensive Insurance Policy with Delivery Cover</h2>
                        <h5>As the name suggests, the Star Comprehensive Insurance Policy is designed to provide comprehensive and overall cover for all your medical expenses. Of course, the plan offers many unique childbirth-related coverage benefits when both husband and wife are covered under the Comprehensive Insurance Policy. Mid-term inclusion of the newly married spouse or new born baby is permissible on intimation by paying an additional premium. The coverage starts from the date of payment of the premium. Expecting parents can avail in-patient delivery and new born expenses coverage after a waiting period of 24 months.</h5>
                        <h5><b>Note:</b> A waiting period of 24 months applies afresh for second delivery following a delivery claim.</h5>
                        <h5><b>The following delivery and newborn expenses are covered under the Star Comprehensive Insurance policy.   </b></h5>
                        <ul>
                            <li>Expenses incurred during delivery, including cesarean section up to a maximum of two deliveries in the insured’s lifetime while the policy is active. </li>
                            <li>Pre- and post-natal expenses for delivery, including cesarean section up to the limits mentioned.</li>
                            <li>Expenses incurred in hospital/nursing home on the treatment for the new born for any disease, illness including any congenital disorders, and accidental injuries up to the limits mentioned.</li>
                            <li>Vaccination expenses are covered up to the limits mentioned until the baby turns one year old.</li>
                        </ul>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/comprehensive-health-insurance-plan"
                            }}>
                        Know more
                            </Button>
                        </h5>
                        <h2 className={classes.h2hTitle}>Young Star Insurance Policy (Gold Plan)</h2>
                        <h5>As a healthy young adult, it may appear unnecessary to purchase health insurance. Even so, having insurance is essential, so you do not have to face any unexpected medical costs, especially in an emergency.</h5>
                        <h5>Young Star Insurance Policy is a good option for young adults aged between 18 and 40 years to meet their specific healthcare requirements. The plan offers a wide range of benefits such as incentive-led wellness programs, low waiting periods, coverage of pre-and post-hospitalisation expenses, cumulative bonus, annual health check-ups, and automatic restoration of the sum insured.</h5>
                        <h5>Young Star Insurance Policy is here to help you through your journey of childbirth. The Gold Plan offers many unique benefits like delivery and childbirth-related cover, when both husband and wife have been covered under this policy for a continuous period of 36 months. Delivery and new born expenses coverage begin after a waiting period of 36 months from the policy inception date.</h5>
                        <h5><b>Note:</b> A waiting period of 24 months applies afresh for second delivery claim.</h5>
                        <h5><b>The following delivery expenses are covered under the Young Star Insurance Policy (Gold Plan)</b></h5>
                        <ul>
                            <li>Expenses incurred during normal delivery, including cesarean section up to ₹ 30000 per delivery, and this benefit is applicable up to a maximum of two deliveries in the insured’s lifetime while the policy is active.</li>
                            <li>Pre- and post-natal expenses for delivery, including cesarean section up to Rs. 30,000/-</li>
                            <li>The coverage for a new born starts from the 91st day of their birth upon adding them under the policy with prior intimation.</li>
                            
                        </ul>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/young-star-insurance"
                            }}>
                        Know more
                            </Button>
                        </h5>
                        <h2 className={classes.h2hTitle}>Star Women Care Insurance Policy</h2>
                        <h5>When women and girls lead healthy lives and achieve their potential, the entire community benefits. Women’s health is not just a women’s issue. Those days are gone when women played the gender roles of typical caretakers and homemakers. As time passed, women emerged as global leaders and were empowered. In recent times, women are working hard to take care of family and balance their work effortlessly. More and more women are career-driven and emerging as decision-makers.When it comes to ladies’ health, they ignore to secure and take protection. When a severe illness strikes, they are obligated to forego their financial freedom and career.</h5>
                        <h5>Star Women Care Insurance Policy is a women-centric policy to cater to the needs of women with kids and spouses included. This policy is available for persons in the age group of 18 – 75 years. This policy offers financial assistance for Maternity, Newborn cover, in-utero fetal surgeries, assisted reproduction treatments, and many more, forming a component of female developmental concerns. Renewal benefits like waiting periods for maternity and others for a female child prevail.</h5>
                        <h5><b>The following maternity expenses are payable under the Star Women Care Insurance Policy.</b></h5>
                        <ul>
                            <li>Delivery expenses covered for childbirth, either by Normal or Caesarean, vary from Rs. 25,000/- up to Rs. 1,00,000/- </li>
                            <li>Assisted reproduction treatment for subfertility is covered.</li>
                            <li>In-utero fetal surgeries are payable up to the Sum Insured</li>
                            <li>Family planning surgeries are payable up to the sum insured</li>
                            <li>Four pediatric consultations per year at Rs. 500/- per consultation is payable for the baby till 12 years of age.</li>
                            <li>Metabolic screening for a new-born baby is payable once up to 3500/-</li>
                            <li>Out-patient consultations on confirmation of pregnancy for Ante-natal care are available.</li>
                            
                        </ul>
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/young-star-insurance"
                            }}>
                        Know more
                            </Button>
                        </h5>
                        <h2 className={classes.h2hTitle}>Super Surplus Insurance Policy (Gold plan) </h2>
                        <h5>The Super Surplus Insurance Policy is a top-up health insurance policy that helps to cover your hospital expenses when your default insurance plan falls short. The policy term under the gold plan is 1 year and 2 years and lifelong renewal is available. The major benefits under this top-up plan include coverage of expenses for all daycare procedures, in-patient hospitalisation, delivery expenses, organ donor expenses, and air ambulance cover.</h5>
                        <h5>Super Surplus Insurance Policy (Gold plan) covers delivery-related expenses and offers financial assistance at the time of delivery. The policy is available for persons between the age of 18 and 65 years. This plan is a good option for people who want to increase their sum insured without paying a higher premium.</h5>
                        <h5><b>The following delivery expenses are covered under this policy.</b></h5>
                        <ul>
                            <li>Expenses incurred during normal delivery, including cesarean section up to ₹ 50000 per policy period, and this benefit is applicable up to a maximum of two deliveries in the lifetime of the insured while the policy is active.</li>
                            <li>Pre- and post-natal expenses for delivery, including cesarean section up to Rs. 50,000/-</li>
                            <li>Expenses incurred on lawful termination of pregnancy.</li>
                        </ul>
                        <h5><b>What is included in Star Health Insurance policies:</b></h5>
                        <ul>
                            <li>Delivery including c-section and newborn expenses up to their limits specified in the policy.</li>
                            <li>Pre- and post-natal expenses of delivery.</li>
                            <li>Covers hospitalisation charges of the newborn due to any medical complications</li>
                            <li>Vaccinations for the newborn until one year up to the limits mentioned in the policy.</li>
                            <li>Expenses incurred on lawful termination of pregnancy.</li>
                        </ul>
                        <h5><b>What is not included in Star Health Insurance policies:</b></h5>
                        <ul>
                            <li>Rest Cure, rehabilitation and respite care</li>
                            <li>Expenses related to the surgical treatment of obesity</li>
                            <li>Change-of-Gender treatments</li>
                            <li>Cosmetic or Plastic Surgery</li>
                            <li>Hazardous or Adventure sports</li>
                            <li>Expenses incurred towards treatment in any hospital or by any Medical Practitioner or any other provider specifically excluded by the Insurer and disclosed in its website</li>
                            <li>Treatment for Alcoholism, drug or substance abuse or any addictive condition and consequences thereof</li>
                        </ul>
                        <h5><b>Eligibility Criteria</b></h5>
                        <h5>A medical policy with maternity benefits covers delivery-related expenses subject to waiting periods.  We recommend Plan for Family well in advance and to buy a maternity cover.  Most of the insurance policies have a waiting period ranging from 12-36 months for delivery expenses to be covered.</h5>
                        
                        <h5>
                        <Button
                            variant='contained'
                            color='primary'
                            className={`${classes.button}`}
                            onClick={()=>{
                                browserWindow.location.href="/super-surplus-health-insurance-policy"
                            }}>
                        Know more
                            </Button>
                        </h5>
                        <h2 className={classes.h2hTitle}>Why is a medical policy with maternity benefits important?</h2>
                        <h5>Pregnancy and going through labour are important phases of a woman’s life. While there is certainly the joy of becoming a parent, most women experience the accompanying anxiety as well. So one thing you need not worry about during this time is hospitalisation expenses. If you have an insurance policy that provides cover for maternity expenses, you can put your worries to rest and enjoy your pregnancy, knowing that all the costs are taken care of.</h5> 
                        <h5>With the escalating medical costs across the country and the surge in delivery expenses in private hospitals, opting for maternity insurance cover and family medical insurance are the best approaches to tackle expenses.</h5>
                        <h2 className={classes.h2hTitle}>Advantages of buying medial policy with delivery benefit</h2>
                        <ul>
                            <li><h3>Financial backup </h3>
                            <h5>Maternity insurance covers expenses incurred during either normal or cesarean delivery. No matter what medical treatment you may require, you need not stress or worry. Moreover, policies with maternity plans cover pre and post-natal expenses.</h5></li>
                            <li><h3>Covers new born baby</h3>
                            <h5>Our plans namely Star comprehensive, Young Star (Gold) and Super Surplus Gold plan provide coverage for the new born from day one. This includes expenses due to medical emergencies and vaccinations as well.</h5></li>
                            <li><h3>Covers delivery expenses</h3>
                            <h5>Buying a Mediclaim plan with maternity benefits offers financial protection during pregnancy. It takes care of pre-and post-natal expenses, ambulance charges, delivery expenses, irrespective of a normal or cesarean delivery. </h5></li>
                            
                        </ul>
                        <h2 className={classes.h2hTitle}>What should you consider before buying a Mediclaim plan with maternity benefits? </h2>
                        <h5>Every parent deserves the best health insurance policy to cover delivery expenses. Handling the high maternity care costs without medical insurance might be tough for both the parents. As a result, the best method to organise your finances is to purchase a maternity health insurance policy, ensuring a healthier and pleasant parenthood. </h5>
                        <h5>Here are some things to consider when choosing a medical policy with maternity benefits </h5>
                        <ul>
                            <li>Choose the finest maternity insurance plan that protects you from various medical bills, not just hospitalisation costs. </li>
                            <li>Every household needs to save money. As a result, check for premium savings you can take advantage of.</li>
                            <li>Browse the listing of Cashless Network Hospitals to ensure that you have easy accessibility to cashless facility in the event of a medical emergency.</li>
                            <li>Understand the policy’s inclusions, exclusions, sub-limits, and waiting period by reading the policy documents.</li>
                        </ul>
                        <h5>By carefully comparing and selecting the proper policy with maximum coverage and features, you may acquire maternity coverage at an affordable cost. </h5> 
                        <h5>Why choose a policy from Star Health Insurance? </h5>
                        <ul>
                            <li><h3>Pre- and post-natal care </h3>
                            <h5>Not all health insurers offer to cover pre-and post-natal delivery expenses. It is truly an advantage of Star Health offering coverage for your pre and post-natal expenses.</h5></li>
                            <li><h3>Cashless facility</h3>
                            <h5>Expecting mothers can avail of cashless facility from more than 11,000+ network hospitals across the country.</h5></li>
                            <li><h3>Fast and hassle-free claims settlement </h3>
                            <h5>Star Health Insurance policyholders can get prompt claim settlement across 14000+ network hospitals, which is a key benefit at the time of childbirth to spend time healing and taking care of your loved ones. At Star Health you can get your claims settled in a hassle-free manner with no TPA (Third-Party Administrator) involvement as per the policy terms and conditions. </h5></li>
                        </ul>
                <GridItem  style={{ paddingLeft:'0px'}}>
                    <h2 className={classes.h2hTitle}>FAQ’s</h2>
                </GridItem>
                <div className={classes.section}>
                    <div className={classes.container}>
                        <GridContainer
                        className={`${classes.container} ${classes.textCenter}`}
                        >
                        <GridItem
                            md={12}
                            style={{ paddingLeft:'0px'}}
                            className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                        ></GridItem>
                        <GridItem
                        style={{ paddingLeft:'0px'}}
                            className={`${classes.mrAuto} ${classes.textCenter}`}
                            xs={12}
                            sm={12}
                            md={12}
                        >
                            <Accordion active={0} collapses={AccordionArray} />
                        </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </GridItem>
            {process.env.ENABLE_HELMET == `true` && (
                <Helmet>
                    <script type="application/ld+json">
                        {`
                        {
                            "@context":"https://schema.org",
                            "@type":"FAQPage",
                            "mainEntity":[
                                {
                                    "@type":"Question",
                                    "name":"Can I get maternity insurance while I am pregnant? ",
                                    "acceptedAnswer":{
                                       "@type":"Answer",
                                       "text":"Unfortunately no. You can buy a health insurance policy when you are pregnant. Maternity benefits cannot be availed as delivery coverage is subject to a minimum of 12 months waiting period (under the Super Surplus Gold Plan). Star Comprehensive Insurance Policy and Young Star Insurance Policy covers delivery expenses with a waiting period of 24 months and 36 months respectively.   "
                                    }
                                 },
                                 {
                                    "@type":"Question",
                                    "name":"Is there any maternity insurance without a waiting period? ",
                                    "acceptedAnswer":{
                                       "@type":"Answer",
                                       "text":"Unfortunately, Maternity insurance is not under the retail health insurance policies. Delivery expenses have some waiting period ranging from 12 months – 36 months. However, you may be exempted from the waiting period for maternity coverage in a group tailor made policy.  "
                                    }
                                 },
                                 {
                                    "@type":"Question",
                                    "name":"Can my new born get coverage under the maternity policy?  ",
                                    "acceptedAnswer":{
                                       "@type":"Answer",
                                       "text":"Yes, your newborn can be added to the policy at the time of renewal or can be included mid-term, subject to a prior intimation from the date of birth.   "
                                    }
                                 },
                                 {
                                    "@type":"Question",
                                    "name":"When should I get a maternity health insurance plan?  ",
                                    "acceptedAnswer":{
                                       "@type":"Answer",
                                       "text":"At least 3 years to 1 year in advance is suggested, to ensure that the waiting periods in any policy do not affect its coverage when planning a baby.  "
                                    }
                                 },
                                 {
                                    "@type":"Question",
                                    "name":"How do I get medical insurance for my newborn baby? ",
                                    "acceptedAnswer":{
                                       "@type":"Answer",
                                       "text":"The feature of adding the newborn in the middle of the Policy Period is termed as Mid-term Inclusion. You can cover your newborn under your existing medical insurance, provided the policy facilitates the coverage. Just intimate Star health insurance within 90 days from the date of birth of the newborn baby, the newborn baby will be covered from 91st day. Under Health insurance policies like Comprehensive Insurance Policy and Young Star Insurance Policy have the feature of mid-term inclusion. Mid-term inclusion is as per the terms and conditions of the Policy "
                                    }
                                 },
                                 {
                                    "@type":"Question",
                                    "name":"Does the policy cover more than one delivery expense?",
                                    "acceptedAnswer":{
                                       "@type":"Answer",
                                       "text":"Yes, of course. The Comprehensive Insurance Policy and Young Star Health Insurance policies provide benefits to cover the second child's birth. Maternity-related benefits are available for two deliveries during the lifetime under this policy."
                                    }
                                 }
                            ]
                         }
                        `}</script>
                </Helmet>
            )}
        </GridContainer>
  );
};
ParentsPageSEO.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default withStyles(WellnessBenefitsStyle)(ParentsPageSEO);